import ReactDOM from 'react-dom'
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, from, ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { message } from 'antd'
import * as Sentry from '@sentry/browser'
import { upperCase } from 'lodash-es'
import * as FullStory from '@fullstory/browser'
import { BrowserTracing } from '@sentry/tracing'
import SentryFullStory from '@sentry/fullstory'
import Statsig from 'statsig-js'
import VConsole from 'vconsole'
import { isMobile } from 'react-device-detect'
import { BrowserRouter } from 'react-router-dom'
import AuthRoutes from './routes/authRoute'
import ErrorBoundary from './errorBoundary'
import { GRAPHQL_URI, STATSIG_ENV_TIER, STATSIG_CLIENT_KEY } from './config.json'
import getQueryVariable from './utils/getQueryVariable'
import userAgent from './utils/getUserAgent'
import './styles/font.less'
import './index.less'
import './app.less'

let staging = window.STATSIG_ENV_TIER !== '__STATSIG_ENV_TIER__' ? window.STATSIG_ENV_TIER : STATSIG_ENV_TIER
staging = staging && staging.toLowerCase()
let hasErrorQuery = false
const isApp = JSON.parse(localStorage.getItem('isApp') || 'false')
const sentryErrors = [
  `Failed to execute 'insertBefore' on 'Node'`,
  'Load failed',
  'The object can not be cloned',
  'Failed to fetch',
  `undefined is not an object (evaluating 'a.h')`,
]

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      ApplicationDeletePayload: { fields: { id: { read: (_, { variables: { id } }) => id } } },
      ApplicationFeeSettingDTO: { fields: { value: { read: value => value / 100 } } },
      Query: {
        fields: {
          tenantWorkOrders: {
            merge: (existing, incoming, { args }) => {
              if (existing && incoming) {
                return [...incoming, ...existing]
              }
              return incoming
            },
            read: (existing, { args }) => existing,
          },
          tripalinkUser: {
            merge: (exiting, incoming) => {
              if (!incoming) {
                localStorage.clear()
                return null
              }
              return incoming
            },
          },
        },
      },
    },
  }),
  link: from([
    new ApolloLink((operation, forward) => {
      operation.setContext(({ headers = {} }) => ({
        headers: { ...headers, Authorization: `Bearer ${localStorage.getItem('token') || ''}` },
      }))

      return forward(operation)
    }),
    onError(({ graphQLErrors, networkError }) => {
      if (window.location.pathname.includes('application') || window.location.pathname.includes('renew')) {
        return
      }
      if (graphQLErrors && Array.isArray(graphQLErrors)) {
        graphQLErrors.forEach(({ message: errorMessage, extensions: { errorType, response } = {} }) => {
          console.error(errorMessage)
          if (errorMessage === 'Access is denied') {
            setTimeout(() => {
              if (isApp) {
                window.gotoFlutterLoginPV()
              }
              window.location.href = '/login'
              window.localStorage.clear()
            }, 1000)
          }

          if (['PERMISSION_DENIED'].includes(errorType)) {
            console.error(errorType)
          }
          // Login invalid case
          const { status = 0 } = response || {}

          if (status === 401 && !hasErrorQuery) {
            hasErrorQuery = true
            message.warn('Login invalid, please login again')
            setTimeout(() => {
              if (isApp) {
                window.gotoFlutterLoginPV()
              }
              window.location.href = '/login'
              window.localStorage.clear()
            }, 1000)
          }
        })
      }
      if (networkError) {
        console.error('networkError', networkError)
      }
    }), // web(staging/production/local server(development)) : development(debug)
    new HttpLink({ uri: staging === 'development' ? GRAPHQL_URI : '/graphql' }),
  ]),
})
Statsig.initialize(window.STATSIG_CLIENT_KEY !== '__STATSIG_CLIENT_KEY__' ? window.STATSIG_CLIENT_KEY : STATSIG_CLIENT_KEY, {
  statsigEnvironment: {
    tier: upperCase(window.STAGE),
  },
  userID: localStorage.getItem('userId') || '',
  ip: window.STATSIG_ENV_IP !== '__STATSIG_ENV_IP__' ? window.STATSIG_ENV_IP : '',
  custom: {
    ...userAgent,
    ...getQueryVariable(),
  },
})

if (window.FULLSTORY_ORG) {
  // only work on staging/production
  FullStory.init({ orgId: window.FULLSTORY_ORG })
}

if (window.STAGE === 'production' && window.SENTRY_DSN && window.SENTRY_ORG) {
  Sentry.init({
    dsn: window.SENTRY_DSN,
    integrations: [new SentryFullStory(window.SENTRY_ORG || ''), new BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1,
    environment: 'production',
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend: (event, hint) => {
      const error = hint.originalException
      for (let i = 0; i < sentryErrors.length; i += 1) {
        if (error?.message?.includes(sentryErrors[i])) {
          return null
        }
      }
      return event
    },
  })
}

const { isVconsole } = getQueryVariable()

if ((staging !== 'production' && (isApp || isMobile)) || isVconsole === 'true') {
  new VConsole()
}

ReactDOM.render(
  <ErrorBoundary>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <AuthRoutes />
      </BrowserRouter>
    </ApolloProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
)
